import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LanguageUtil from "@/commons/LanguageUtil";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import RoutePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import noPhoto from "@/assets/images/common/error_project.png";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import CommonService from "@/services/CommonService";
export default defineComponent({
    name: "Members",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        DatePicker: DatePicker
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var routePath = RoutePath;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/',
                    name: "ダッシュボード"
                },
                {
                    route: RoutePath.MEMBERS,
                    name: "メンバー一覧"
                }
            ],
            listMembers: [],
            totalMembers: 0,
            fieldSortName: "",
            teamSortOrder: "asc",
            filter: {
                categories: [],
            },
            page: 1,
            loading: false,
            preload: true,
            isOrganization: false
        });
        var timeout = null;
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        function onChangeFilterCategories() {
        }
        function loadMoreMembers() {
            state.loading = true;
            state.page += 1;
            getListMembers();
        }
        function sortTeamNames() {
            state.teamSortOrder = state.teamSortOrder === "desc" ? "asc" : "desc";
            state.preload = true;
            state.loading = true;
            state.page = 1;
            getListMembers();
        }
        function getListMembers() {
            return teamInteractor.getTeamsMembers({
                page: state.page,
                team_sort_order: state.teamSortOrder
            }).then(function (result) {
                if (!result.data || result.data.total_count == 0) {
                    state.listMembers = [];
                    state.totalMembers = 0;
                    state.loading = false;
                    state.preload = false;
                    return;
                }
                ;
                if (state.page === 1) {
                    state.listMembers = result.data.data;
                }
                else {
                    state.listMembers = state.listMembers.concat(result.data.data);
                }
                state.loading = false;
                state.preload = false;
                state.totalMembers = result.data.total_count;
            }).catch(function (error) {
                state.loading = false;
                state.preload = false;
            });
        }
        function replaceByDefault(e) {
            e.target.src = noPhoto;
        }
        onMounted(function () {
            var categorySearch = store.state.categoryIdSearch;
            if (categorySearch) {
                state.filter.categories.push(categorySearch);
                store.commit("setCategoryIdSearch", null);
            }
            state.isOrganization = CommonService.isOrganization() ? true : false;
            getListMembers();
        });
        return {
            state: state,
            routePath: routePath,
            onChangeFilterCategories: onChangeFilterCategories,
            sortTeamNames: sortTeamNames,
            replaceByDefault: replaceByDefault,
            noPhoto: noPhoto,
            loadMoreMembers: loadMoreMembers
        };
    }
});
