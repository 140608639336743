<template>
  <div class="members">
    <Navigation />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="members__container container">
      <div class="members__container__right main-area">
        <div class="members__container__right__table">
          <div class="members__container__right__table__title">
            メンバー一覧
          </div>
          <div class="members__container__right__table__pagination">
            {{ state.listMembers.length }}件表示 /
            {{ state.totalMembers }}件中
          </div>
          <div class="members__container__right__table__detail">
            <table>
              <thead>
                <tr>
                  <th>
                    サムネイル
                  </th>
                  <th>
                    メンバー名
                  </th>
                  <th>
                    経験/知見のある業態
                  </th>
                  <th>
                    デジタルスキル/ビジネススキル
                  </th>
                  <th>
                    居住地
                  </th>
                  <th>
                    <a
                      :class="{
                        active: state.teamSortOrder === 'asc'
                      }"
                      @click="sortTeamNames()"
                    >
                      チーム
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody v-if="state.preload">
                <tr>
                  <td colspan="6">
                    <div
                      v-for="idx in 5"
                      :key="idx"
                      class="preloader"
                    ></div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="state.listMembers.length">
                <tr
                  v-for="row of state.listMembers"
                  :key="row.id"
                >
                  <td>
                    <img
                        @error="replaceByDefault"
                        :src="row.profile_image_url || noPhoto"
                        :alt="row.name"
                    />
                  </td>
                  <td>
                      <router-link :to="'/user/profile/' + row.account_id + '/info'">
                        {{ row.name }}
                      </router-link>
                  </td>
                  <td>
                      <span v-if="row.skills.length">
                        <span v-for="(item, i) in row.skills" :key="i">
                          {{ item.name }}
                          <span v-if="i !== row.skills.length - 1">
                            /
                          </span>
                        </span>
                      </span>
                      <span v-if="!row.skills.length">-</span>
                  </td>
                  <td>
                      <span v-if="row.skills_digital_and_business.length">
                        <span v-for="(item, i) in row.skills_digital_and_business" :key="i">
                          {{ item.name }}
                          <span v-if="i !== row.skills_digital_and_business.length - 1">
                            ,<br>
                          </span>
                        </span>
                      </span>
                      <span v-else>-</span>
                  </td>
                  <td>
                      {{ row.residence_prefecture_name || '-' }}
                  </td>
                  <td>
                      <span v-if="row.team">
                        <span v-for="(item, i) in row.team" :key="i">
                          <span v-if="i"> / </span>
                          <span v-if="state.isOrganization">
                            <router-link :to="routePath.TEAMS + '/' + item.team_id">
                              {{ item.team_name}}
                            </router-link>
                          </span>
                          <span v-else>
                            {{ item.team_name}}
                          </span>
                        </span>
                      </span>
                      <span v-else>-</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              v-show="
                state.listMembers.length < state.totalMembers &&
                state.listMembers.length > 0
              "
              class="btn-s btn-next-light-blue shadow"
              @click="loadMoreMembers"
              :disabled="state.loading"
            >
              <i v-if="state.loading" class="fas fa-circle-notch fa-spin"></i>
              {{ state.aLang.US015_table_load_more_btn }}
            </button>
          </div>
          <div
            class="members__container__right__table__detail__no_data"
            v-if="state.listMembers.length == 0 && !state.preload"
          >
            {{ state.aLang.member_list_table_no_data }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./Members.ts" />
<link type="scss" src="./Members.scss" />
